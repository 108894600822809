
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "error-404",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const usuario = AuthService.getUsuario();
    // const nivelCargo = usuario.nivelCargo;

    function goToHome() {
      // if (nivelCargo == 3) {
      //   router.push({ name: "erroConcessionaria" });
      // } else {
      //   router.push({ name: "login" });
      // }
      router.push({ name: "login" });
    }

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      goToHome,
    };
  },
});
